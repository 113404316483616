import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { getDoc, doc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { db } from '../firebase.config'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import shareIcon from '../assets/svg/shareIcon.svg'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

function Listing() {
	const [listing, setListing] = useState(null)
	const [loading, setLoading] = useState(true)
	const [shareLinkCopied, setShareLinkCopied] = useState(false)
	const navigate = useNavigate()
	const params = useParams()
	const auth = getAuth()

	useEffect(() => {
		const fetchListing = async () => {
			const docRef = doc(db, 'listings', params.listingId)
			const docSnap = await getDoc(docRef)

			if (docSnap.exists()) {
				setListing(docSnap.data())
				setLoading(false)
			} else {
				navigate('/')
				toast.error('Listing does not exist')
			}
		}
		fetchListing()
	}, [navigate, params.listingId])

	const numberWithCommas = (num) => {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}

	if (loading) {
		return <Spinner />
	}
	return (
		<main>
			<Swiper slidesPerView={1} pagination={{ clickable: true }}>
				{listing.imgUrls.map((url, index) => (
					<SwiperSlide key={index}>
						<div
							className='swiperSlideDiv'
							style={{
								background: `url(${listing.imgUrls[index]}) center no-repeat`,
								backgroundSize: 'cover',
							}}></div>
					</SwiperSlide>
				))}
			</Swiper>
			<div
				className='shareIconDiv'
				onClick={() => {
					navigator.clipboard.writeText(window.location.href)
					setShareLinkCopied(true)
					setTimeout(() => {
						setShareLinkCopied(false)
					}, 2000)
				}}>
				<img src={shareIcon} alt='share' />
			</div>
			{shareLinkCopied && <p className='linkCopied'>Link copied!</p>}

			<div className='listingDetails'>
				<p className='listingName'>
					{listing.name} - $
					{listing.offer
						? numberWithCommas(listing.discountedPrice)
						: numberWithCommas(listing.regularPrice)}
				</p>
				<p className='listingLocation'>{listing.location}</p>
				<p className='listingType'>
					For {listing.type === 'rent' ? 'rent' : 'sale'}
				</p>
				{listing.offer && (
					<p className='discountPrice'>
						${numberWithCommas(listing.regularPrice - listing.discountedPrice)}{' '}
						discount
					</p>
				)}
				<ul className='listingDetailsList'>
					<li>
						{listing.bedrooms > 1
							? `${listing.bedrooms} Bedrooms`
							: '1 Bedroom'}
					</li>
					<li>
						{listing.bathrooms > 1
							? `${listing.bathrooms} Bathrooms`
							: '1 Bathroom'}
					</li>
					<li>{listing.parking && 'Parking Sport'}</li>
					<li>{listing.furnished && 'Furnished'}</li>
				</ul>
				<p className='listingLocationTitle'>Location</p>

				<div className='leafletContainer'>
					<MapContainer
						style={{ height: '100%', width: '100%' }}
						center={[listing.geolocation.lat, listing.geolocation.lng]}
						zoom={13}
						scrollWheelZoom={false}>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						/>
						<Marker
							position={[listing.geolocation.lat, listing.geolocation.lng]}>
							<Popup>{listing.location}</Popup>
						</Marker>
					</MapContainer>
				</div>

				{auth.currentUser?.uid !== listing.userRef && (
					<Link
						to={`/contact/${listing.userRef}?listingName=${listing.name}`}
						className='primaryButton'>
						Contact Landlord
					</Link>
				)}
			</div>
		</main>
	)
}

export default Listing
